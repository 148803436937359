@import '@/styles/config';
@import '@/styles/mixins/typography';
@import '@/styles/mixins/box-shadow';
@import '@/styles/mixins/inputs';

.container {
  max-width: map-get($map: $spacing, $key: siteMaxWidth);
  margin: 0 auto;
  padding: map-get($map: $spacing, $key: sitePadding);
}

.callout {
  margin: 1rem 0;
}

.app {
  background-color: var(--gray-100);
  border-radius: 12px;
  padding: 24px;
  margin: 24px 0;
  text-align: center;

  h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    color: var(--gray-900);
  }

  p {
    font-size: 16px;
    color: var(--gray-600);
    margin-bottom: 16px;
  }
}

.appButtons {
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
}

.appButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
  padding: 10px 20px;
  background-color: var(--white);
  color: var(--gray-900);
  font-weight: 500;
  border: 1px solid var(--gray-300);
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.2s ease;
  text-decoration: none;

  &:hover {
    background-color: var(--gray-50);
    border-color: var(--gray-400);
  }
}

.header {
  width: 100%;
  background-color: map-get($map: $colors, $key: foreground);
  padding: 2rem 0;

  &-container {
    max-width: map-get($map: $spacing, $key: siteMaxWidth);
    margin: 0 auto;
    padding: 0 map-get($map: $spacing, $key: sitePadding);
    display: flex;
    align-items: flex-start;
    gap: 2rem;

    &:after {
      content: '';
      clear: both;
      display: table;
    }

    @include breakpoint(large, max) {
      // flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &-seller-info {
    padding-right: 2rem;
    width: 60%;

    &-container {
      display: flex;
      align-items: center;
    }

    h1 {
      @include heading();
    }

    h2 {
      @include copy();
      color: map-get($map: $colors, $key: grey);
      margin-top: 0.25rem;
    }

    p {
      margin-top: 1rem;
      white-space: pre-wrap;
      @include copy();

      @include breakpoint(large, max) {
        width: 100%;
        text-align: center;
      }
    }

    &-reviews {
      margin-top: 0.25rem;

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    &-image {
      position: relative;
      min-width: 10rem;
      min-height: 10rem;
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
    }

    @include breakpoint(large, max) {
      width: 100%;
      float: none;
      padding-right: 0;

      &-image {
        margin: 0 auto;
      }

      &-container {
        text-align: center;
        display: block;
      }
    }
  }

  &-seller-actions {
    width: 40%;
    padding-left: map-get($map: $spacing, $key: sitePadding) * 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5rem;

    @include breakpoint(large, max) {
      margin-top: 1rem;
      justify-content: center;
      align-items: center;
      width: auto;
      padding-left: 0;
    }
  }

  &-seller-stats {
    width: 100%;
    max-width: 30rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    &-stat {
      text-align: center;

      h3 {
        @include copy($font-size: small);
        white-space: nowrap;
        margin-bottom: 0.25rem;
      }

      span {
        @include heading();
        color: map-get($map: $colors, $key: primary);
      }
    }
  }

  &-seller-badges {
    min-width: 100%;
    display: flex;
    margin-top: 0.5rem;
    margin-bottom: 0.75rem;

    &-pill {
      margin-right: 1rem;
      background-color: map-get($map: $colors, $key: light) !important;

      &:last-of-type {
        margin-right: 0;
      }
    }

    @include breakpoint(large, max) {
      justify-content: center;
    }
  }

  &-seller-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;

    &--follow {
      min-width: 15rem;

      button {
        padding: 1rem;
      }
    }

    &--action {
      width: 100%;
      max-width: calc(100% - 5rem);
      display: flex;
      gap: map-get($map: $spacing, $key: sitePadding);
      justify-content: center;

      > * {
        flex: 1;
        min-width: 15rem;
      }
    }

    @include breakpoint(large, max) {
      justify-content: center;

      &--follow {
        min-width: 8rem;
      }

      &--action {
        justify-content: center;
        max-width: none;

        > * {
          flex: auto;
        }
      }
    }
  }
}

.image-active-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;

  @include breakpoint(large, max) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

.pill {
  margin-top: -1.5rem;
  z-index: 1;
}

.searchContainer {
  width: 100%;
  margin-bottom: 1rem;
}

.controlsContainer {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;

  @include breakpoint(medium, max) {
    width: 100%;
  }
}

.sortWrapper {
  display: flex;
  gap: 0.5rem;
  flex-shrink: 0;
  flex-wrap: nowrap;

  @include breakpoint(medium, max) {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 0.25rem;
  }
}

.search-input-container {
  position: relative;
  flex-basis: 100%;
  @include input-reset();
  padding-bottom: 1rem;
  width: 100%;
}

.input-container {
  position: relative;

  form {
    position: relative;
  }
}

.input {
  @include input();
  z-index: 2;
  width: 100%;
  background-color: map-get($map: $colors, $key: foreground);

  &-active {
    background-color: map-get($map: $colors, $key: foreground);
  }

  @include breakpoint(medium, min) {
    &-active {
      border-bottom: 1px solid transparent !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.placeholder {
  position: absolute;
  top: 50%;
  left: 1.2rem;
  padding-right: 1rem;
  transform: translateY(-50%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 40px);
  pointer-events: none;
  color: map-get($map: $colors, $key: copyLight);
}

.placeholder-hidden {
  display: none;
}

.input-clear {
  @include copy();
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-icon {
  height: 1.3rem;
  width: 1.3rem;
  position: absolute;
  top: calc(50% - 0.6rem);
  right: 0.75rem;
}

.filterContainer {
  margin-bottom: 24px;
}

.filterResults {
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--gray-600);
  font-size: 14px;
}

.sortSelect {
  position: relative;
  min-width: 140px;
  max-width: 180px;
  white-space: nowrap;

  select {
    @include input();
    width: 100%;
    appearance: none;
    padding-right: 2.5rem;
    background-color: map-get($map: $colors, $key: foreground);
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:focus {
      outline: none;
      border-color: map-get($map: $colors, $key: primary);
    }
  }

  .selectButton {
    @include input();
    width: 100%;
    text-align: left;
    padding-right: 2.5rem;
    padding-left: 0.75rem;
    background-color: map-get($map: $colors, $key: foreground);
    cursor: pointer;
    position: relative;
    border: 1px solid map-get($map: $colors, $key: copyLight);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:focus {
      outline: none;
      border-color: map-get($map: $colors, $key: primary);
    }
  }

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10;
    margin-top: 4px;
    background-color: map-get($map: $colors, $key: foreground);
    border: 1px solid map-get($map: $colors, $key: border);
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    overflow-y: auto;
  }

  .optionsList {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .optionItem {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    cursor: pointer;
    font-size: 14px;
    gap: 8px;

    &:hover {
      background-color: map-get($map: $colors, $key: background);
    }

    &.selected {
      background-color: rgba(map-get($map: $colors, $key: primary), 0.05);
    }

    input[type='checkbox'],
    input[type='radio'] {
      appearance: none;
      width: 16px;
      height: 16px;
      border: 1.5px solid map-get($map: $colors, $key: border);
      border-radius: 3px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &:checked {
        background-color: map-get($map: $colors, $key: primary);
        border-color: map-get($map: $colors, $key: primary);

        &:after {
          content: '';
          width: 6px;
          height: 6px;
          background-color: white;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    input[type='radio'] {
      border-radius: 50%;

      &:checked:after {
        border-radius: 50%;
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid map-get($map: $colors, $key: copyLight);
    pointer-events: none;
  }
}

// Price Selection Component Styles
.priceSelect {
  position: relative;
  min-width: 180px;
  max-width: 240px;
  white-space: nowrap;

  .selectButton {
    @include input();
    width: 100%;
    text-align: left;
    padding-right: 2.5rem;
    padding-left: 0.75rem;
    background-color: map-get($map: $colors, $key: foreground);
    cursor: pointer;
    position: relative;
    border: 1px solid map-get($map: $colors, $key: copyLight);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:focus {
      outline: none;
      border-color: map-get($map: $colors, $key: primary);
    }

    &.active {
      border-color: map-get($map: $colors, $key: primary);
      background-color: rgba(map-get($map: $colors, $key: primary), 0.05);
    }
  }

  .clearButton {
    position: absolute;
    right: 1.8rem;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: map-get($map: $colors, $key: copyLight);
    cursor: pointer;

    &:hover {
      color: map-get($map: $colors, $key: copyDark);
    }
  }

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10;
    margin-top: 4px;
    background-color: map-get($map: $colors, $key: foreground);
    border: 1px solid map-get($map: $colors, $key: border);
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow-y: visible;
  }

  .priceHeader {
    display: flex;
    padding: 12px 12px 0 12px;
    gap: 8px;
    align-items: center;
  }

  .priceHeaderLabel {
    flex: 1;
    min-width: 70px;
    font-size: 14px;
    font-weight: 500;
    color: map-get($map: $colors, $key: copyDark);
    text-align: left;
  }

  .priceHeaderSpacer {
    width: 36px;
    margin-left: 4px;
  }

  .priceInputs {
    display: flex;
    padding: 8px 12px 16px 12px;
    gap: 8px;
    align-items: flex-end;
  }

  .priceInputContainer {
    flex: 1;
    min-width: 70px;
  }

  .priceLabel {
    display: block;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 4px;
    color: map-get($map: $colors, $key: copyDark);
  }

  .priceInput {
    @include input();
    width: 100%;
    padding: 10px 14px;
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    height: 42px;

    &::placeholder {
      color: #666;
    }

    &:focus {
      border-color: map-get($map: $colors, $key: primary);
    }
  }

  .priceSubmitButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    margin-left: 4px;
    background-color: map-get($map: $colors, $key: primary);
    border: none;
    border-radius: 4px;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      fill: white;
    }

    &:hover {
      background-color: darken(map-get($map: $colors, $key: primary), 5%);
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid map-get($map: $colors, $key: copyLight);
    pointer-events: none;
  }
}

.review-modal-title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include box-shadow();
  padding: map-get($map: $spacing, $key: sitePadding);
  border-radius: map-get($map: $spacing, $key: borderRadius);
  cursor: pointer;
  gap: map-get($map: $spacing, $key: sitePadding) / 2;
  flex-shrink: 0;
  background-color: map-get($map: $colors, $key: foreground);

  &:hover {
    background-color: map-get($map: $colors, $key: background);
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    white-space: nowrap;

    &-icon {
      svg {
        width: 2rem;
        height: 2rem;
      }
    }

    .title {
      @include type($size: small, $color: copyDark);
    }

    .count {
      @include type($size: small, $color: copyDark);
    }
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.resultsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.resultsCount {
  margin-bottom: 10px;
  color: var(--gray-600);
  font-size: 0.9rem;
}

.grid {
  width: 100%;
  display: grid;
  grid-gap: map-get($map: $spacing, $key: sitePadding);
  margin-bottom: 2rem;

  // Start with 2 columns for very small devices
  grid-template-columns: repeat(2, 1fr);

  // Extra small devices (phones, 480px and up)
  @include breakpoint(xsmall, min) {
    grid-template-columns: repeat(3, 1fr);
  }

  // Small devices (tablets, 768px and up)
  @include breakpoint(small, min) {
    grid-template-columns: repeat(4, 1fr);
  }

  // Medium devices (desktops, 992px and up)
  @include breakpoint(medium, min) {
    grid-template-columns: repeat(5, 1fr);
  }

  // Large devices (large desktops, 1200px and up)
  @include breakpoint(x-large, min) {
    grid-template-columns: repeat(6, 1fr);
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 2rem 0;
  gap: 0.5rem;

  .pageLink {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 2.5rem;
    height: 2.5rem;
    padding: 0 0.75rem;
    border-radius: map-get($map: $spacing, $key: borderRadius);
    background-color: map-get($map: $colors, $key: foreground);
    color: map-get($map: $colors, $key: copyDark);
    text-decoration: none;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.2s ease;
    border: 1px solid map-get($map: $colors, $key: borderLight);

    &:hover:not(.disabled) {
      background-color: map-get($map: $colors, $key: light);
    }

    &.active {
      background-color: map-get($map: $colors, $key: primary);
      color: map-get($map: $colors, $key: foreground);
      border-color: map-get($map: $colors, $key: primary);
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .ellipsis {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    padding: 0 0.5rem;
    color: map-get($map: $colors, $key: copyLight);
  }
}

.empty-state {
  margin: 3rem 0;
  text-align: center;

  p {
    @include type($font: copy, $size: medium);
    color: map-get($map: $colors, $key: copyLight);
  }
}

.shopPaused {
  margin: 1rem 0 2rem;
  padding: 1rem 1.5rem;
  background-color: map-get($map: $colors, $key: light);
  border-radius: map-get($map: $spacing, $key: borderRadius);
  border-left: 5px solid map-get($map: $colors, $key: warning);

  h3 {
    @include type($font: headingBold, $size: medium);
    color: map-get($map: $colors, $key: warning);
    margin-bottom: 0.5rem;
  }

  p {
    @include type($font: copy, $size: medium);
    color: map-get($map: $colors, $key: copyLight);
  }
}

// Simple filter styles
.simpleFilter {
  display: flex;
  align-items: center;
  gap: 8px;
}

.filterLabel {
  font-weight: 600;
  margin-right: 1rem;
  font-size: 0.875rem;
  color: var(--gray-700);
}

.filterButtons {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.filterButton {
  padding: 4px 12px;
  border-radius: 16px;
  background-color: var(--white);
  border: 1px solid var(--gray-200);
  color: var(--gray-600);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: var(--gray-50);
    border-color: var(--gray-300);
  }

  &.selected {
    background-color: var(--gray-900);
    border-color: var(--gray-900);
    color: var(--white);

    &:hover {
      opacity: 0.9;
    }
  }
}

.formatCount {
  margin-left: 0.25rem;
  font-size: 0.75rem;
  color: inherit;
  opacity: 0.8;
}

.priceInputContainer {
  position: absolute;
  top: 100%;
  left: -30px;
  width: 300px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  margin-top: 8px;
  padding: 8px;
}

.priceRangeInputs {
  display: flex;
  align-items: center;
  gap: 12px;
}

.priceInputWrapper {
  flex: 1;
  min-width: 110px;
}

.priceInput {
  width: 100%;
  padding: 10px 14px;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  height: 42px;

  &::placeholder {
    color: #666;
  }

  &:focus {
    border-color: map-get($map: $colors, $key: primary);
  }
}

.priceSearchButton {
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  width: 40px;
  height: 42px;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &:hover {
    background-color: #eaeaea;
  }

  svg {
    color: #666;
    width: 18px;
    height: 18px;
  }
}

.searchWrapper {
  border: 1px solid var(--gray-200);
  border-radius: 8px;
  box-shadow: var(--shadow-sm);
  padding: 1rem;
  background: white;
}
